#frappe-palleon-style{

    // #palleon{

.sp-container {
    position:absolute;
    top:0;
    left:0;
    display:inline-block;
    *display: inline;
    *zoom: 1;
    /* https://github.com/seballot/spectrum/issues/40 */
    z-index: 9999994;
    overflow: hidden;
}
.sp-original-input-container {
    position: relative;
    display: inline-flex;
}
.sp-original-input-container input {
    margin: 0 !important;
}
.sp-original-input-container .sp-add-on {
    width: 40px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
input.spectrum.with-add-on {
    /*padding-left: 45px;*/
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
.sp-original-input-container .sp-add-on .sp-colorize {
    height: 100%;
    width: 100%;
    border-radius: inherit;
}
.sp-colorize-container {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='%23ccc' fill-opacity='1'%3E%3Crect x='0' y='0' width='6' height='6' /%3E%3Crect x='6' y='6' width='6' height='6' /%3E%3C/svg%3E");
}
.sp-container.sp-flat {
    position: relative;
}

/* Fix for * { box-sizing: border-box; } */
.sp-container,
.sp-container * {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
}

/* http://ansciath.tumblr.com/post/7347495869/css-aspect-ratio */
.sp-top {
    position:relative;
    width: 100%;
    display:inline-block;
}
.sp-top-inner {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}
.sp-color {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:20px !important;
}
.sp-hue {
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    width: 12px;
    height: 100%;
    left: initial !important;
}

.sp-clear-enabled .sp-hue {
    top: 15%;
    height: 85%;
}

.sp-fill {
    padding-top: 80%;
}
.sp-sat, .sp-val {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.sp-alpha-enabled .sp-top {
    margin-bottom: 28px !important;
}
.sp-alpha-enabled .sp-alpha {
    display: block;
}
.sp-alpha-handle {
    position: absolute;
    top: -3px;
    cursor: pointer;
    height: 16px;
    border-radius: 50%;
    width: 16px;
    margin-right: 5px;
    left: -2px;
    right: 0;
    background: #f9f9f9;
    box-shadow: 0 0 2px 0px #3a3a3a;
}
.sp-alpha {
    display: none;
    position: absolute;
    bottom: -18px;
    right: 0;
    left: 0;
    height: 10px;
}
.sp-alpha-inner {
    border-radius: 4px;
}

.sp-clear {
    display: none;
}

.sp-clear.sp-clear-display {
    background-position: center;
}

.sp-clear-enabled .sp-clear {
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 0;
    cursor: pointer;
    left: initial;
    height: 14px;
    width: 14px;
}

/* Don't allow text selection */
.sp-container, .sp-replacer, .sp-preview, .sp-dragger, .sp-slider, .sp-alpha, .sp-clear, .sp-alpha-handle, .sp-container.sp-dragging .sp-input, .sp-container button  {
    -webkit-user-select:none;
    -moz-user-select: -moz-none;
    -o-user-select:none;
    user-select: none;
}

.sp-container.sp-input-disabled .sp-input-container {
    display: none;
}
.sp-container.sp-buttons-disabled .sp-button-container {
    display: none;
}
.sp-container.sp-palette-buttons-disabled .sp-palette-button-container {
    display: none;
}
.sp-palette-only .sp-picker-container {
    display: none;
}
.sp-palette-disabled .sp-palette-container {
    display: none;
}

.sp-initial-disabled .sp-initial {
    display: none;
}


/* Gradients for hue, saturation and value instead of images.  Not pretty... but it works */
.sp-sat {
    background-image: -webkit-gradient(linear,  0 0, 100% 0, from(#FFF), to(rgba(204, 154, 129, 0)));
    background-image: -webkit-linear-gradient(left, #FFF, rgba(204, 154, 129, 0));
    background-image: -moz-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
    background-image: -o-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
    background-image: -ms-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
    background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
    filter : progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
}
.sp-val {
    border-radius: 4px;
    background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
    background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
    background-image: -moz-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
    background-image: -o-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
    background-image: -ms-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
    background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
    filter : progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}

.sp-hue {
    background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
    background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
    background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
    background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
    background: linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}

/* IE filters do not support multiple color stops.
   Generate 6 divs, line them up, and do two color gradients for each.
   Yes, really.
 */
.sp-1 {
    height:17%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}
.sp-2 {
    height:16%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}
.sp-3 {
    height:17%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}
.sp-4 {
    height:17%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}
.sp-5 {
    height:16%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}
.sp-6 {
    height:17%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}

.sp-hidden {
    display: none !important;
}

/* Clearfix hack */
.sp-cf:before, .sp-cf:after { content: ""; display: table; }
.sp-cf:after { clear: both; }
.sp-cf { *zoom: 1; }

/* Mobile devices, make hue slider bigger so it is easier to slide */
@media (max-device-width: 480px) {
    .sp-color { right: 40%; }
    .sp-hue { left: 63%; }
    .sp-fill { padding-top: 60%; }
}
.sp-dragger {
    border-radius: 5px;
    height: 10px;
    width: 10px;
    border: 1px solid #fff;
    cursor: pointer;
    position:absolute;
    top:0;
    left: 0;
    margin-left: 3px;
    margin-top: 3px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
}
.sp-slider {
    position: absolute;
    top: 0;
    cursor: pointer;
    height: 16px;
    border-radius: 50%;
    width: 16px;
    left: -2px;
    background: #f9f9f9;
    box-shadow: 0 0 2px 0px #3a3a3a;
    margin-top: 8px;
}

/*
Theme authors:
Here are the basic themeable display options (colors, fonts, global widths)
*/

.sp-container {
    display: inline-flex;
    border-radius: 0;
    background-color: white;
    padding: 0;
    border-radius: 4px;
    color: black;
    box-shadow: 0 0 0 1px rgba(99,114,130,.16), 0 8px 16px rgba(27,39,51,.08);
}
.sp-container, .sp-container button, .sp-container input, .sp-color, .sp-hue, .sp-clear {
    font-size:12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.sp-top {
    margin-bottom: 10px;
}
.sp-color, .sp-hue, .sp-clear, .sp-val, .sp-sat {
    border-radius: 3px;
}

.sp-input-container {
    margin-top: -5px;
}
.sp-input-container.sp-cf,
.sp-initial.sp-thumb.sp-cf,
.sp-button-container.sp-cf {
    height: 25px;
}
.sp-picker-container .sp-cf {
    margin-bottom: 10px;
}

.sp-palette-row-initial > span:first-child {
    cursor: pointer;
}

/* Input */
.sp-initial-disabled  .sp-input-container {
    width: 100%;
}
.sp-input {
    padding: 0px 5px !important;
    margin: 0;
    width: 100%;
    box-shadow: none !important;
    height: 100% !important;
    background: transparent;
    color: #3a3a3a;
    border-radius: 2px !important;
    border: 1px solid #e0e0e0 !important;
    text-align: center;
    font-family: monospace;
    font-size: inherit !important;
}
.sp-input:focus  {
    border: 1px solid orange;
}
.sp-input.sp-validation-error {
    border: 1px solid red;
    background: #fdd;
}
.sp-picker-container , .sp-palette-container {
    position: relative;
    padding: 10px;
}
.sp-picker-container {
    width: 200px;
    padding-bottom: 0;
}

/* Palettes */
.sp-palette-container {
    border-right: solid 1px #ccc;
}

.sp-palette-only .sp-palette-container {
    border: 0;
}

.sp-palette .sp-thumb-el {
    display: block;
    position:relative;
    float:left;
    width: 24px;
    height: 15px;
    margin: 3px;
    cursor: pointer;
    border:solid 2px transparent;
}
.sp-palette .sp-thumb-el:hover, .sp-palette .sp-thumb-el.sp-thumb-active {
    border-color: orange;
}
.sp-thumb-el {
    position:relative;
}

/* Initial */
.sp-initial {
    float: left;
}
.sp-initial span {
    width: 30px;
    height: 25px;
    border:none;
    display:block;
    float:left;
    margin:0;

}
.sp-initial .spe-thumb-el.sp-thumb-active {
    border-radius: 0 5px 5px 0;
}
.sp-initial .spe-thumb-el{
   border-radius: 5px 0 0 5px;
}

.sp-initial .sp-clear-display {
    background-position: center;
}

/* Buttons */
.sp-button-container {
    float: right;
}
.sp-palette-button-container {
    margin-top: 10px;
}

/* Replacer (the little preview div that shows up instead of the <input>) */
.sp-replacer {
    position: relative;
    overflow:hidden;
    cursor:pointer;
    display:inline-block;
    border-radius: 3px;
    border: 1px solid #aaa;
    color: #666;
    transition: border-color .3s;
    vertical-align: middle;
    width: 3rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
}
.sp-replacer.sp-disabled {
    cursor:default;
    border-color: silver;
    color: silver;
}
.sp-dd {
    position: absolute;
    font-size: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 2px;
    line-height: 1.6rem;
    background-color: white
}
.sp-preview {
    position:relative;
    width: 100%;
    height: 100%;
    float:left;
    z-index: 0;
}
.sp-preview-inner {
    transition: background-color .2s;
}
.sp-preview-inner.sp-clear-display {
    display: none;
}

/* Color Picker */
.sp-palette .sp-thumb-el {
    width: 16px;
    height: 16px;
    margin: 3px;
    border: none;
    border-radius: 3px;
}


/* Buttons: http://hellohappy.org/css3-buttons/ */
.sp-container button {
    border-radius: 3px;
    border: none;
    background: transparent;
    line-height: 1;
    padding: 0 8px;
    height: 25px;
    text-transform: capitalize;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #606c72;
    font-weight: bold;
}
.sp-container button.sp-choose {
    background-color: #3cab3b;
    color: #fff;
    margin-left: 5px;
}
.sp-container button:hover {
    opacity: .8;
}
.sp-container button.sp-palette-toggle {
    width: 100%;
    background-color: #f3f3f3;
    margin: 0;
}


.sp-palette span:hover, .sp-palette span.sp-thumb-active {
    border-color: #000;
}

.sp-preview, .sp-alpha, .sp-thumb-el {
    position:relative;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='%23ccc' fill-opacity='1'%3E%3Crect x='0' y='0' width='6' height='6' /%3E%3Crect x='6' y='6' width='6' height='6' /%3E%3C/svg%3E");
}
.sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner {
    display:block;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}

.sp-palette .sp-thumb-inner {
    border-radius: 3px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath d='M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
}

.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath d='M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}

.sp-clear-display {
    background-repeat:no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAABe0lEQVQokYXSsUtcQRTF4d8Jj+VhHSxkEQuLsEUKK0nhTBFTmLSSUhBCMCAWsmgIwWrBLk0akfwLCaSQKBJmtrIIISwpRFKIhViETScphGMzysMtvOVwvpm5d0bGNCuGWAOPgYdl6S8wSDn9b+bUhDHEKWAdeAFMANg+l/TV9ofcz6cjMIbYBvaBMds7QCqZ58CmpBNgPuV0DvAAIMyFGugWtJr7eTv38xEwkPRPErY7QDeG2LqFkjrAgu0dSd/KDVqSNmxvAZ8lfbS9AHRuYemnLWkv5XRVBrQMbAI/gTXgEzAJtJuwBVS2L2OIle03QA/4Lmkl5XQBXEqqbFcAVYFDYChpFngiqWf7l6TXKaezMt2Zkhk24THwG+jZriX9AFZvUAyxLbRke2D75O5zPAO+ADXwEtizjaRHwDvbTyUtppwOmicCHAJvbXcl9YA1SQDjtseA97YPRz7ATcUQp2y/kjRdevsjaTfldNrMjcDGBjXA3T96L7yvrgFzP69+0Ao/HAAAAABJRU5ErkJggg==');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14'%3E%3Cpath d='M12 20.016q3.281 0 5.648-2.367t2.367-5.648q0-2.672-1.734-4.922l-11.203 11.203q2.25 1.734 4.922 1.734zM3.984 12q0 2.672 1.734 4.922l11.203-11.203q-2.25-1.734-4.922-1.734-3.281 0-5.648 2.367t-2.367 5.648zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
}

// }

}