/* ---------------------- 
Stylesheet Guide
-------------------------

MATERIAL ICONS
LOADER
GENERAL STYLES
PAGE STRUCTURE
TOP BAR
DROPDOWN
CONTENT BAR
PAGES
ICON MENU
CONTENT
LAYERS
GRID
ELEMENTS
APPS
BUTTONS
BLOCKS
TOOLTIP
PAGINATION
ACCORDION
RANGESLIDER
COUNTER
CONTROLS
COLORPICKER
SELECT
TABS
TOGGLE
CHECKBOX
CUSTOM CURSOR
MODAL
TEMPLATE LIBRARY
MEDIA LIBRARY
SVG LIBRARY
ICONFINDER
PEXELS
PIXABAY
MASONRY
FRONT-END EDITOR
MEDIA QUERIES

*/

#frappe-palleon-style {

//   #palleon{

/* ================= LOADER ================== */

.palleon-loader-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center
}

.palleon-loader-wrap.hide {
    display: none !important;
}

.palleon-loader {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    -webkit-animation: palleonSpin 1s infinite linear;
    animation: palleonSpin 1s infinite linear
}

@-webkit-keyframes palleonSpin {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes palleonSpin {
    100% {
        transform: rotate(360deg)
    }
}

/* ================= GENERAL STYLES ================== */

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 14px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 20px;
}

html,
body {
    padding: 0;
    margin: 0;
}

html {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0 0 15px 0;
    line-height: 1.2;
    font-weight: 700;
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.85rem
}

h3 {
    font-size: 1.7rem
}

h4 {
    font-size: 1.55rem
}

h5 {
    font-size: 1.4rem
}

h6 {
    font-size: 1.25rem
}

p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0 0 15px 0;
}

select:disabled,
input:disabled,
button:disabled {
    pointer-events: none !important;
}

.input-hidden {
    position: absolute;
    left: -9999px;
}

#palleon-icon-panel hr {
    display: block;
    border: none;
    margin: 25px 0;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

#palleon-custom-element-options,
#palleon-noframes,
#palleon-noelements,
#palleon-noicons,
#palleon-text-settings,
#palleon-image-settings,
#palleon-shape-settings,
#shape-gradient-settings,
#text-gradient-settings,
#element-gradient-settings,
#palleon-custom-shape,
#palleon-brush-pattern-width,
#palleon-brush-pattern-distance,
#shape-custom-width-wrap,
#palleon-overlay-wrap,
#palleon-noiconfinder {
    display: none;
}

#palleon-overlay-preview {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

#palleon-noicons {
    margin-top: 0;
}

#palleon-custom-shape {
    margin-top: 20px;
}

.notice {
    display: block;
    padding: 12px 15px;
    border-radius: 4px;
    font-weight: 700;
    margin: 15px 0;
}

.notice h6 {
    margin-bottom: 0.5rem;
}

.palleon-img-wrap {
    position: relative;
    min-height: 120px;
    width: 100%;
}

.palleon-frame .palleon-img-wrap {
    min-height: 60px;
}

.palleon-img-loader {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    -webkit-animation: palleonSpin 1s infinite linear;
    animation: palleonSpin 1s infinite linear;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    margin: -15px 0 0 -15px;
}

textarea {
    max-width: 100%;
}

/* ================= PAGE STRUCTURE ================== */

#palleon-body {
    position: relative;
    height: 100vh;
    margin: 0;
    padding: 60px 200px 0 415px;
}

.frappe-palleon.panel-closed #palleon-body {
    padding-left: 75px;
}

.frappe-palleon.layers-closed #palleon-body {
    padding-right: 0;
}

.palleon-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.palleon-inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    z-index: 1;
}

#palleon-toggle-left,
#palleon-toggle-right {
    position: fixed;
    height: 60px;
    top: 50%;
    transform: translateY(-30px);
    z-index: 10;
    width: 22px;
    cursor: pointer;
    text-align: center;
}

#palleon-toggle-left {
    left: 414px;
    border-left: none !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#palleon-toggle-right {
    right: 199px;
    border-right: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 8;
}

#palleon-toggle-left.closed {
    display: none !important;
}

#palleon-toggle-right.closed {
    right: 0;
}

#palleon-toggle-left .material-icons,
#palleon-toggle-right .material-icons {
    font-size: 22px;
    line-height: 60px;
    text-align: center;
    transition: color 0.2s ease-in-out;
}

/* ================= TOP BAR ================== */

#palleon-top-bar {
    display: flex;
    width: 100%;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    align-items: center;
    z-index: 99;
}

.palleon-top-bar-menu {
    margin-left: auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.palleon-top-bar-menu>div {
    margin-left: 10px;
}

.palleon-logo {
    padding: 15px;
    height: 60px;
}

.palleon-logo img {
    height: 100%;
    width: auto;
}

.palleon-logo img.logo-desktop {
    display: block;
}

.palleon-logo img.logo-mobile {
    display: none;
}

/* ================= DROPDOWN ================== */

#palleon-user-menu {
    display: flex;
    align-items: center;
}

#palleon-user-menu img {
    width: 32px;
    height: auto;
    vertical-align: bottom;
    border-radius: 100%;
}

.palleon-dropdown-wrap {
    position: relative;
    cursor: pointer;
}

ul.palleon-dropdown {
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 9;
    border-radius: 4px;
    display: none;
}

ul.palleon-dropdown li {
    padding: 10px;
}

ul.palleon-dropdown li:last-child {
    border: none !important;
}

ul.palleon-dropdown li a {
    display: block;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 0.2s ease-in-out;
}

ul.palleon-dropdown li a .material-icons {
    font-size: 15px;
    width: 22px;
}

.palleon-dropdown-wrap>.material-icons {
    transition: transform 0.2s linear;
    transform: rotate(0);
}

.palleon-dropdown-wrap.opened>.material-icons {
    transform: rotate(180deg);
}

/* ================= CONTENT BAR ================== */

.palleon-content-bar {
    position: fixed;
    display: flex;
    bottom: 20px;
    right: 220px;
    padding: 0 25px;
    border-radius: 100px;
    align-items: center;
    overflow: hidden;
    z-index: 10;
    visibility: hidden;
}

.frappe-palleon.layers-closed .palleon-content-bar {
    right: 20px;
}

.palleon-content-bar .palleon-img-size {
    line-height: 40px;
    padding-right: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.palleon-img-size .material-icons {
    font-size: 10px;
}

.palleon-content-bar .palleon-counter {
    align-items: center;
    margin-left: 0;
    max-width: 105px;
    padding: 0 20px 0 10px;
    position: relative;
    height: 40px;
}

.palleon-content-bar .palleon-counter .palleon-btn,
.palleon-content-bar .palleon-counter .palleon-btn:hover,
.palleon-content-bar .palleon-counter .palleon-btn:focus {
    min-width: 15px;
}

.palleon-content-bar .palleon-counter .palleon-btn .material-icons {
    font-size: 16px;
}

.palleon-counter .palleon-btn:disabled {
    opacity: 1;
}

.palleon-content-bar .palleon-counter .palleon-form-field {
    border: none !important;
    background: transparent !important;
    font-weight: 700
}

.palleon-content-bar .palleon-counter:after {
    font-family: 'Material Icons';
    content: 'percent';
    position: absolute;
    right: -25px;
    line-height: 40px;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    font-size: 12px;
}

#palleon-img-drag {
    padding: 0 10px;
    align-self: stretch;
    border-radius: 0;
}

#palleon-img-drag .material-icons {
    margin: 0;
    font-size: 16px;
}

/* ================= PAGES ================== */

#palleon-pages {
    display: flex;
    padding: 0 6px;
    min-height: 52px;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
}

#palleon-pages>div {
    position: relative;
    flex:1;
    overflow: hidden;
    max-width: 110px;
    top:auto !important;
    bottom:0 !important;
    transition: none !important;
}

#palleon-pages>div>.palleon-open-page {
    display: block;
    padding: 0 30px 0 15px;
    height: 46px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: 44px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

#palleon-pages>div.active {
    max-width: 160px;
}

#palleon-pages>div>.material-icons {
    font-size: 16px;
    margin-left: 5px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top:-9px;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    z-index: 2;
}

#palleon-pages>div>.material-icons:hover {
    transform: scale(1.2);
}

/* ================= ICON MENU ================== */

#palleon-icon-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 75px;
    display: flex;
    flex-direction: column;
    padding: 60px 0 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;
    height: 100%;
}

button.palleon-icon-menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    cursor: pointer;
    height: auto;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    transition: color 0.2s ease-in-out;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.palleon-icon-menu-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.9rem;
    padding-top: 6px;
    text-transform: uppercase;
    font-weight: 700;
}

button.palleon-icon-menu-btn.stick-to-bottom {
    margin-top: auto;
}

button.palleon-icon-menu-btn .material-icons {
    font-size: 22px;
}

#palleon-icon-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 415px;
    padding: 60px 0 0 75px;
    overflow: hidden;
    z-index: 10;
    height: 100%;
}

#palleon-icon-panel-inner {
    display: block;
    overflow: auto;
    height: 100%;
    padding: 25px;
}

.palleon-icon-panel-content.panel-hide {
    display: none !important;
}

.palleon-panel-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

/* ================= CONTENT ================== */

#palleon-content {
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#palleon .canvas-container {
    z-index: 1;
}

.palleon-panel-subtitle {
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 20px
}

#palleon-canvas-img-wrap {
    display: none;
}

#palleon-canvas-img {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

#palleon-canvas-wrap {
    position: relative;
    z-index: 9;
    visibility: hidden;
}

#palleon-canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 99;
    cursor: grab;
}

#palleon-canvas-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

#palleon-canvas-loader .palleon-loader {
    width: 40px;
    height: 40px;
    border-width: 3px;
}

/* ================= LAYERS ================== */

#palleon-right-col {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    overflow: auto;
    z-index: 7;
    height: 100%;
    padding: 60px 0 0 0;
    display: flex;
    flex-direction: column;
}

.palleon-layers-title {
    padding: 15px 10px;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.palleon-layers-title .material-icons {
    font-size: 1.25rem;
    margin-right: 5px;
}

#palleon-layers {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

#palleon-layers li {
    position: relative;
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
    font-weight: 700;
    line-height: 46px;
    height: 48px;
    font-size: 1rem;
    justify-content: space-between;
    overflow: hidden;
    transition: color 0.1s linear;
    cursor: move;
}

#palleon-layers li .layer-icons {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 0 10px;
    z-index: 9;
    width: 80%;
    display: none
}

#palleon-layers li .layer-icons a {
    cursor: pointer;
    height: 100%;
    min-width: 0;
    text-align: center;
    width: 25%;
}

#palleon-layers li .layer-icons .material-icons {
    font-size: 16px;
    line-height: 46px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    opacity: 1;
    transition: opacity 0.1s linear;
}

#palleon-layers li .layer-icons .material-icons:hover {
    opacity: 0.8;
}

#palleon-layers li .layer-name {
    width: 100%;
    padding: 0 25% 0 0;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 1rem;
}

#palleon-layers>li>.material-icons {
    min-width: 30px;
    text-align: center;
    font-size: 18px;
}

#palleon-layers>li>.material-icons.layer-settings {
    font-size: 16px;
    width: 20%;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.1s linear;
}

#palleon-no-layer {
    padding: 20px;
    font-style: italic;
    font-size: 0.9rem;
}

#palleon-no-layer a {
    display: block;
    text-align: right;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
}

#palleon-no-layer a:hover {
    text-decoration: underline;
}

#palleon-layer-delete-wrap {
    margin-top: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    visibility: hidden;
}

#palleon-layer-delete-wrap .palleon-btn {
    margin-left: 5px;
    height: 34px;
    padding: 0 10px;
}

#palleon-layer-delete-wrap .palleon-btn .material-icons {
    margin: 0;
    font-size: 16px
}

/* ================= GRID ================== */

.palleon-grid {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(min(80px, 100%), 1fr));
    column-gap: 10px;
    row-gap: 10px;
}

.palleon-grid.two-column {
    grid-template-columns: repeat(auto-fill, minmax(min(100px, 100%), 1fr));
}

.palleon-grid.three-column {
    grid-template-columns: repeat(auto-fill, minmax(min(70px, 100%), 1fr));
}

.palleon-grid.four-column {
    grid-template-columns: repeat(auto-fill, minmax(min(50px, 100%), 1fr));
}

.palleon-grid.five-column {
    grid-template-columns: repeat(auto-fill, minmax(min(50px, 100%), 1fr));
    column-gap: 5px;
    row-gap: 5px;
}

.palleon-grid.pexels-grid.media-library-grid,
.palleon-grid.template-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(120px, 100%), 1fr));
    min-height: 80px;
}

.palleon-frames-grid .grid-item,
.palleon-grid .grid-item {
    position: relative;
    cursor: pointer;
}

.palleon-frames-grid img,
.palleon-grid img {
    vertical-align: bottom;
    width: 100%;
    height: auto;
}

.palleon-grid.svg-library-grid,
.palleon-grid.media-library-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(100px, 100%), 1fr));
}

.palleon-grid .palleon-masonry-item-inner {
    margin-bottom: 0;
    display: flex;
    height: 100%;
    align-items: center;
}

.palleon-grid .palleon-masonry-item {
    height: 100%;
}

.palleon-grid .notice {
    grid-column: auto / span 4;
    margin: 0 !important;
}

.template-grid.palleon-grid .notice {
    grid-column: auto / span 7;
    margin: 0 !important;
}

.palleon-frames-grid .notice {
    margin-bottom: 0 !important;
}

.palleon-frame,
.palleon-element {
    position: relative;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.type-customSVG>a:before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-transform: none;
    content: 'color_lens';
    margin-right: 5px;
    font-size: 1.1rem;
}

.template-favorite,
.palleon-frame .frame-favorite,
.palleon-element .element-favorite {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
}

.grid-item:hover .template-favorite,
.palleon-frame:hover .frame-favorite,
.palleon-element:hover .element-favorite {
    visibility: visible;
    opacity: 1;
}

.template-favorite {
    right: 0px;
    top: 0px;
    width: 25px;
    height: 25px;
}

.template-favorite>.palleon-btn-simple>.material-icons,
.palleon-frame>.frame-favorite>.palleon-btn-simple>.material-icons,
.palleon-element>.element-favorite>.palleon-btn-simple>.material-icons {
    font-size: 18px;
}

.palleon-frames-grid .palleon-frame {
    margin-top: 10px;
    padding: 0;
}

.palleon-frames-grid {
    margin-top: -10px;
}

#palleon-frame-favorites .palleon-frames-grid {
    margin-top: 0;
}

.grid-icon-item {
    text-decoration: none;
    display: block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.grid-icon-item div {
    width: 100%;
    text-align: center;
}

.grid-icon-item .material-icons {
    font-size: 36px;
    padding: 10px 0;
}

.grid-icon-label {
    display: block;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 5px 0;
}

/* ================= ELEMENTS ================== */

#palleon-filters.palleon-grid img,
#palleon-filter-library.palleon-grid img,
#palleon-filter-library.palleon-grid canvas {
    border: 3px solid transparent;
    border-radius: 0;
    cursor: pointer;
}

#palleon-filters.palleon-grid .palleon-element,
#palleon-filters.palleon-grid .palleon-element:hover {
    border: none;
}

#palleon-filters.palleon-grid div label span {
    display: block;
    text-align: center;
    padding: 5px 7px;
    font-size: 0.9rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0px;
    right: 0px;
    line-height: 1;
    border-top-left-radius: 4px;
}

#palleon-filter-library .grid-item.none .material-icons {
    position: absolute;
    top:50%;
    left:50%;
    font-size: 30px;
    line-height: 1;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

#palleon-filter-library .grid-item.none img {
    visibility: hidden !important;
}

.palleon-element>.material-icons {
    font-size: 26px;
    line-height: 1.4;
}

.palleon-shape {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.palleon-shape svg {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

#palleon-shape-loadmore {
    margin-top: 20px
}

/* ================= APPS ================== */

.palleon-apps-menu-item {
    position: relative;
    padding: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.palleon-apps-menu-item img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.palleon-apps-menu-item-desc {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1rem;
    line-height: 1;
    padding: 8px 12px 8px 8px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.palleon-btn.palleon-close-app {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding: 10px;
}

.palleon-app-btns {
    display: flex;
    align-items: center
}

.palleon-app-btns>* {
    flex: 1;
}

.palleon-app-btns>*:first-child {
    margin-right: 5px;
}

.palleon-app-btns>*:last-child {
    margin-left: 5px;
}

.palleon-app-peview {
    padding: 10px;
    margin-bottom: 20px
}

.palleon-app-peview img,
.palleon-app-peview svg {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.palleon-colorbrewer-item {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
}

.palleon-colorbrewer-item>div {
    flex: 1;
    height: 22px;
}

.palleon-colorbrewer-item.random {
    justify-content: center;
    height: 40px;
    background: #12c2e9;
    background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
    background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
}

.palleon-colorbrewer-item.random .material-icons {
    color: #fff;
    font-size: 24px;
    line-height: 39px;
}

#palleon-multiavatar-name {
    margin-bottom: 20px;
}

/* ================= BUTTONS ================== */

.palleon-btn-simple {
    border: none !important;
    background: none !important;
    outline: none !important;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 1rem;
}

.palleon-btn {
    padding: 8px 12px;
    margin: 0;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.palleon-btn.btn-full {
    width: 100%;
}

.palleon-btn:disabled {
    opacity: 0.7;
}

.palleon-btn .material-icons {
    font-size: 0.9rem;
    margin-right: 4px;
}

.btn-full {
    width: 100%;
}

.palleon-btn.palleon-lg-btn {
    padding: 12px;
}

#text-flip-y .material-icons,
#palleon-flip-vertical .material-icons,
#img-flip-vertical .material-icons {
    transform: rotate(90deg);
}

#palleon-crop-btns.disabled {
    pointer-events: none !important;
    opacity: 0.5;
}

/* ================= BLOCKS ================== */

.palleon-btn-group {
    display: flex;
}

.icon-group .palleon-btn {
    padding: 8px 0;
    flex: 1;
    border-radius: 0;
}

.icon-group .palleon-btn:last-child {
    border: none !important;
}

.icon-group .palleon-btn .material-icons {
    font-size: 16px;
    margin: 0;
}

.palleon-aspect-ratio {
    display: flex;
    align-items: center;
}

.palleon-resize-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.palleon-aspect-ratio>.material-icons,
.palleon-resize-wrap>.material-icons {
    font-size: 12px;
    min-width: 20px;
    text-align: center;
}

.palleon-aspect-ratio .palleon-btn,
.palleon-resize-wrap .palleon-btn {
    margin-left: 10px;
    padding: 0;
    height: 34px;
    min-width: 34px;
}

.palleon-aspect-ratio .palleon-btn .material-icons,
.palleon-resize-wrap .palleon-btn .material-icons {
    font-size: 1rem;
    margin: 0;
}

.palleon-btn-set {
    display: flex;
    align-items: center;
}

.palleon-btn-set .palleon-btn:first-child {
    width: 100%
}

.palleon-btn-set .palleon-btn:last-child .material-icons {
    margin: 0;
    font-size: 16px;
}

.palleon-btn-set .palleon-btn:last-child {
    margin-left: 5px;
    min-width: 40px;
}

.palleon-select-btn-set {
    display: flex;
    align-items: stretch;
}

.palleon-select-btn-set .palleon-btn {
    margin-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
}

.palleon-select-btn-set .palleon-btn .material-icons {
    margin: 0;
    font-size: 16px;
}

.palleon-block-50 {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px
}

.palleon-block-50:last-child {
    margin: 0
}

.palleon-block-50>* {
    width: 50%;
}

.palleon-block-50>*:first-child {
    margin-right: 5px;
}

.palleon-block-50>*:last-child {
    margin-left: 5px;
}

.palleon-block-50 .palleon-btn {
    height: 34px;
}

.palleon-block-33 {
    display: flex;
    align-items: flex-end;
}

.palleon-block-33>div {
    flex: 1
}

.palleon-block-33>div:first-child {
    margin-right: 10px;
}

.palleon-block-33>div:last-child {
    margin-left: 10px;
}

.palleon-block-50 label,
.palleon-block-33 label {
    font-weight: 700;
    margin: 0 0 5px 0 !important;
    display: block;
}

/* ================= TOOLTIP ================== */

.tooltip {
    position: relative;
}

.tooltip:after {
    content: attr(data-title);
    font-size: 0.9rem;
    position: absolute;
    padding: 7px 10px;
    bottom: -10px;
    left: 50%;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    transform: translateX(-50%) translateY(100%);
    text-transform: none;
    font-weight: 700;
    border-radius: 2px;
    line-height: 1.2;
}

.tooltip:before {
    border: solid;
    border-width: 0 6px 6px 6px;
    bottom: -10px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99999;
    visibility: hidden;
    transform: translateX(-50%);
}

.tooltip:hover:after,
.tooltip:hover:before {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

.tooltip.tooltip-top:after {
    top: -10px;
    bottom: auto;
    transform: translateX(-50%) translateY(-100%);
}

.tooltip.tooltip-top:before {
    border-width: 6px 6px 0 6px;
    top: -10px;
    bottom: auto;
}

/* ================= PAGINATION ================== */

.palleon-pagination ul {
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.palleon-pagination ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    padding: 0;
    font-size: 1rem;
    line-height: 30px;
    min-width: 30px;
    font-weight: 700;
    border-radius: 4px;
}

.palleon-pagination ul li.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.palleon-pagination ul li .material-icons {
    font-size: 1rem;
    line-height: 30px;
    margin: 0;
    padding: 0;
    display: block;
}

.palleon-pagination ul li a {
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center
}

.palleon-accordion .palleon-pagination ul {
    margin: 20px 0 0 0;
    justify-content: center;
}

.palleon-accordion .palleon-pagination ul li {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    line-height: 1;
    min-width: 0;
    border: none;
    border-radius: 0;
}

.palleon-accordion .palleon-pagination ul li .material-icons {
    font-size: 0.9rem;
    line-height: 1;
}

.palleon-accordion .palleon-pagination ul li a,
.palleon-accordion .palleon-pagination ul li>span {
    padding: 7px;
}

/* ================= ACCORDION ================== */

ul.palleon-accordion {
    margin: 0 0 25px 0;
    padding: 0;
    list-style: none;
    border-bottom: none;
}

ul.palleon-accordion>li {
    margin: 0 0 10px 0;
    padding: 0;
    border-radius: 4px;
}

ul.palleon-accordion>li>div {
    padding: 20px;
    display: none;
}

ul.palleon-accordion>li.opened>div {
    display: block;
}

ul.palleon-accordion>li>a {
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.2s ease-in-out;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 8px 5px 8px 10px;
    cursor: pointer;
}

ul.palleon-accordion>li>a>.arrow {
    transform: rotate(0deg);
    font-size: 20px;
}

ul.palleon-accordion>li.opened>a>.arrow {
    transform: rotate(180deg);
}

ul.palleon-accordion>li>a>.arrow {
    margin-left: auto;
}

.material-icons.accordion-icon {
    font-size: 1.1rem;
    margin-right: 5px;
}

ul.palleon-accordion>li>a>.data-count {
    font-family: 'Roboto', sans-serif;
    line-height: 1.1;
    font-size: 0.8rem;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: 5px;
}

#palleon-filters-li > a > .arrow,
#palleon-quick-filters-li > a > .arrow,
#palleon-frames ul.palleon-accordion>li>a>.arrow,
#palleon-elements ul.palleon-accordion>li>a>.arrow {
    margin-left: 0;
}

.material-icons.my-favorites-star {
    font-size: 1.2rem;
    margin-right: 0.25rem;
}

/* ================= RANGESLIDER ================== */

.palleon-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 3px;
    outline: none;
    opacity: 1;
}

.palleon-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: none;
    box-shadow: none;
    border-radius: 100%;
}

.palleon-slider::-moz-range-thumb {
    width: 14px;
    height: 14px;
    cursor: pointer;
    border: none;
    box-shadow: none;
    border-radius: 100%;
}

/* ================= COUNTER ================== */

.palleon-counter {
    display: flex;
    align-items: stretch;
    max-width: 120px;
    margin-left: auto;
}

.palleon-counter .palleon-btn,
.palleon-counter .palleon-btn:hover,
.palleon-counter .palleon-btn:focus {
    padding: 0;
    border-radius: 0;
    min-width: 30px;
}

.palleon-counter .palleon-btn .material-icons {
    margin: 0;
}

.palleon-counter .palleon-form-field {
    text-align: center;
}

.palleon-control-wrap.label-block .palleon-counter {
    max-width: 100%;
}

/* ================= CONTROLS ================== */

.palleon-control-wrap {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
}

.palleon-control-wrap.hidden {
    display: none;
}

.palleon-accordion .conditional-settings .palleon-control-wrap,
.palleon-accordion .conditional-settings .palleon-control-wrap:first-child,
.palleon-control-wrap {
    margin-top: 15px;
}

#palleon-qrcode-settings .palleon-control-wrap:first-child,
#palleon-settings .palleon-control-wrap:first-child,
.palleon-accordion .palleon-control-wrap:first-child {
    margin-top: 0;
}

.palleon-control-wrap.nomargin {
    margin-bottom: 0;
}

.palleon-control-wrap.label-block {
    flex-direction: column;
}

.palleon-control-label,
.palleon-control {
    width: 50%;
    min-width: 50%;
}

.palleon-control-label {
    padding: 0 10px 0 0;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.palleon-control-wrap.label-block .palleon-control-label {
    padding: 0;
}

.palleon-control-label span {
    font-family: 'Roboto', sans-serif;
    margin-left: auto;
    line-height: 1;
    padding: 4px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.palleon-control-wrap.label-block .palleon-control-label,
.palleon-control-wrap.label-block .palleon-control {
    width: 100%;
    min-width: 100%;
}

.palleon-control-wrap.label-block .palleon-control-label {
    margin-bottom: 5px;
}

.palleon-control-wrap.label-block .palleon-control-label.slider-label {
    margin-bottom: 0;
}

.palleon-control-desc {
    font-size: 0.9rem;
    font-style: italic;
    padding-top: 5px;
}

#palleon-draw-settings,
#eraser-width-wrap {
    display: none;
}

.palleon-form-field {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 7px;
    outline: none;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.3;
}

.palleon-form-field:disabled {
    opacity: 0.7;
}

.palleon-hidden-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.palleon-hidden-file+label * {
    pointer-events: none;
}

.palleon-search-box {
    display: flex;
}

.palleon-search-box button {
    margin-left: 5px;
}

.palleon-search-box button .material-icons {
    margin: 0;
    font-size: 16px;
}

.palleon-search-wrap {
    position: relative;
    display: flex;
    margin-bottom: 25px;
}

.palleon-search-wrap .material-icons {
    position: absolute;
    top: 12px;
    right: 10px;
    line-height: 1;
    font-size: 18px;
    z-index: 9;
}

.palleon-search-wrap .palleon-form-field {
    padding: 11px 40px 11px 11px;
    border-radius: 4px;
}

#palleon-element-search-icon.cancel,
#palleon-iconfinder-search-icon.cancel,
#palleon-icon-search-icon.cancel {
    cursor: pointer;
}

.palleon-sub-settings {
    margin-top: 25px;
    padding-top: 10px;
}

.palleon-accordion .palleon-sub-settings {
    margin-top: 20px;
    padding-top: 20px;
}

.palleon-sub-settings.nomargin {
    margin-top: 0px;
    padding-top: 0px;
    border: none;
}

.sp-colorize-container {
    width: 40px !important;
}

.palleon-submit-btns {
    display: flex;
}

.palleon-submit-btns .palleon-btn {
    width: 50%;
}

.palleon-submit-btns .palleon-btn:first-child {
    margin-right: 5px;
}

.palleon-submit-btns .palleon-btn:last-child {
    margin-left: 5px;
}

.palleon-control-group {
    display: flex;
    align-items: flex-end;
}

.palleon-control-group>div {
    width: 25%;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
}

.palleon-control-group>div:last-child {
    margin-right: 0;
}

.palleon-control-group .palleon-btn {
    height: 34px;
    width: 100%;
}

.palleon-control-group label {
    padding: 0 0 5px 0;
    font-weight: 700
}

#palleon-preferences {
    margin-bottom: 20px;
}

#qrcode-preview svg {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    vertical-align: bottom;
}

#palleon-barcode-wrap {
    display: flex;
    justify-content: center;
    padding: 10px;
}

#palleon-barcode-wrap svg {
    max-width: 100%;
}

/* ================= COLORPICKER ================== */

.sp-original-input-container {
    display: flex;
    margin-left: auto !important;
    height: 34px;
}

.palleon-colorpicker,
.palleon-colorpicker:focus,
.palleon-form-field {
    width: 100%;
    padding: 7px;
    outline: none;
    box-shadow: none;
    color: #aaa;
    font-size: 1rem;
}

.sp-clear-display {
    background-image: none;
}

.sp-clear-enabled .sp-clear {
    top: 0;
}

.sp-clear-display:before {
    font-family: 'Material Icons';
    content: 'block';
    line-height: 14px;
    font-size: 14px;
}

/* ================= SELECT ================== */

.palleon-select {
    border-radius: 0;
    cursor: pointer;
    height: 34px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 5px 30px 5px 5px;
    outline: none;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.palleon-select:disabled {
    opacity: 0.7;
}

.palleon-select:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(50, 151, 211, 0.5);
}

.select2-container {
    z-index: 99999;
}

.select2-container--dark {
    max-width: 100%;
}

.select2-container--dark .select2-selection--single {
    border: 0px;
    height: 34px;
}

.select2-container--dark .select2-selection--single .select2-selection__rendered {
    line-height: 32px;
}

.select2-container--dark .select2-selection--single .select2-selection__arrow {
    height: 28px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--dark .select2-selection--single .select2-selection__arrow b {
    top: 60%;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    width: 0;
}

.select2-container--dark .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.select2-container--dark .select2-selection--single {
    border-radius: 0px;
}

.select2-container--dark.select2-container--open .select2-selection__arrow b {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
}

.select2-container--dark span.select2-search input {
    height: 30px !important;
}

.select2-drop {
    margin-top: -2px;
    border-top: 0;
    border-radius: 0px !important;
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.select2-drop.select2-drop-above {
    margin-top: 2px;
    border-bottom: 0;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.select2-container--dark .select2-search {
    margin-top: 3px;
}

.select2-container--dark .select2-search input {
    height: 26px;
    outline: none;
}

.select2-container--dark .select2-search--dropdown {
    padding: 5px;
}

.select2-container--dark .select2-results__group {
    display: block;
    padding: 10px 5px 5px 5px;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.select2-container--dark .select2-results__option {
    padding: 5px;
    line-height: 1.2;
    border-radius: 2px;
}

.select2-container--dark .select2-item {
    display: flex;
    align-items: center;
}

.select2-container--dark .material-icons {
    font-size: 1rem;
    margin-right: 5px;
}

.select2-results>.select2-results__options {
    max-height: 250px;
    overflow: auto;
}

/* ================= TABS ================== */

.palleon-tabs {
    position: relative;
}

.palleon-tabs .palleon-tab {
    display: none;
}

.palleon-tabs .palleon-tab.active {
    display: block;
}

.palleon-tabs-menu {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.palleon-tabs-menu li {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
    padding: 5px 10px;
    line-height: 1.2;
    border-radius: 2px;
    cursor: pointer;
    white-space:nowrap;
}

.palleon-tabs-menu li .material-icons {
    font-size: 1.1rem;
    margin-right: 5px;
}

/* ================= TOGGLE ================== */

.palleon-toggle {
    cursor: pointer;
    display: inline-block;
}

.palleon-toggle-switch {
    display: inline-block;
    border-radius: 20px;
    width: 40px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}

.palleon-toggle-switch:before,
.palleon-toggle-switch:after {
    content: "";
}

.palleon-toggle-switch:before {
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left 0.25s;
}

.palleon-toggle-checkbox:checked+.palleon-toggle-switch:before {
    left: 22px;
}

.palleon-toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

/* ================= CHECKBOX ================== */

.palleon-checkbox {
    display: block;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.palleon-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.palleon-checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
}

.palleon-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.palleon-checkbox input:checked~.palleon-checkmark:after {
    display: block;
}

.palleon-checkbox .palleon-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.palleon-checkbox-control,
.palleon-toggle-control {
    display: flex;
    justify-content: flex-end;
}

.palleon-checkbox-control {
    padding-right: 20px
}

/* ================= CUSTOM CURSOR ================== */

.tm-cursor-none,
.tm-cursor-none * {
    cursor: none !important;
}

.tm-cursor {
    pointer-events: none;
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity 300ms linear, transform 300ms linear, background-color 300ms linear, border 300ms linear, border-radius 300ms linear;
    visibility: hidden;
}

.tm-cursor.moving {
    opacity: 1;
}

.tm-cursor:before {
    pointer-events: none;
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999999;
    transition: opacity 300ms linear, transform 300ms linear, background-color 300ms linear, border 300ms linear, border-radius 300ms linear;
}

.tm-pointer-simple.tm-cursor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 12px rgba(16, 39, 112, 0.2);
}

/* ================= MODAL ================== */

.palleon-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
}

.palleon-modal-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding: 4%;
    overflow: auto
}

.palleon-modal-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    max-width: 800px;
}

.palleon-modal-inner.large {
    max-width: 1000px;
}

.palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    border-bottom: none;
    padding: 0px;
    overflow: hidden;
}

.palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 0;
    margin: 0 4px 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: 1;
}

.palleon-modal-inner>.palleon-tabs>.palleon-tab,
.palleon-modal-bg {
    padding: 40px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.palleon-modal-inner label {
    margin: 0 5px 0 0;
}

#palleon-modal-onstart,
.palleon-modal-close {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
    line-height: 36px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    text-align: center;
    z-index: 99999;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

#palleon-modal-onstart .material-icons,
.palleon-modal-close .material-icons {
    font-size: 20px;
    line-height: 36px;
}

#modal-select-img .palleon-btn-set button,
#modal-select-img .palleon-btn-set .palleon-file-field {
    width: 50%;
}

#modal-select-img .palleon-btn-set .material-icons {
    margin-right: 5px;
}

#modal-select-img label {
    margin: 0;
}

#modal-empty-canvas .palleon-resize-wrap .palleon-btn {
    min-width: 120px;
}

#modal-empty-canvas .palleon-resize-wrap {
    margin: 0;
}

#palleon-download-as-json,
#palleon-save-as-json {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px dashed #303030;
}

.palleon-select-btn-block {
    display: flex;
}

.palleon-select-btn-block>div:first-child {
    width: 75%;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px dashed #303030
}

.palleon-select-btn-block>div:last-child {
    width: 25%;
}

.palleon-select-btn-block .palleon-btn-set .palleon-btn:last-child .material-icons {
    font-size: 0.9rem
}

#palleon-drag-drop-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    height: 200px;
    cursor: pointer;
}

#palleon-drag-drop-upload .material-icons,
#palleon-drag-drop-upload p {
    line-height: 1;
}

#palleon-drag-drop-upload .material-icons {
    font-size: 40px;
}

#palleon-drag-drop-upload p {
    margin: 0.5rem 0 0 0;
    font-size: 1rem;
}

/* ================= TEMPLATE LIBRARY ================== */

.palleon-templates-menu-wrap {
    display: flex;
    margin-bottom: 30px;
    align-items: stretch;
    margin-top: -50px;
    max-width: 450px;
    margin-left: auto;
    z-index: 2;
}

.palleon-templates-menu-wrap>* {
    margin-right: 10px;
}

.palleon-templates-menu-wrap>*:last-child {
    margin-right: 0;
}

.palleon-templates-menu-wrap button .material-icons {
    margin: 0;
    font-size: 16px;
}

.palleon-template-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: none;
}

.palleon-template-list li {
    display: flex;
    align-items: center;
    padding: 12px;
}

.palleon-template-list li.active {
    pointer-events: none;
}

.palleon-template-list li.active button {
    opacity: 0.5;
}

.palleon-template-list li:last-child {
    border: none !important;
}

.palleon-template-list li div {
    flex: 1;
}

.palleon-template-list li div:first-child {
    font-weight: 700;
    font-size: 1rem
}

.palleon-template-list li div:last-child {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.palleon-template-list li div:last-child button {
    margin-left: 10px;
    font-size: 0.8rem;
    padding: 6px 10px;
}

.palleon-template-list .notice {
    margin: 20px;
}

#palleon-history-list li .info {
    display: flex;
    align-items: center;
}

#palleon-history-list li .info .material-icons {
    min-width: 30px;
    text-align: center;
    font-size: 18px;
}

#palleon-history-list li .info span.time {
    font-size: 0.9rem;
    line-height: 1;
    padding: 4px;
    margin: 0 10px;
    border-radius: 2px;
}

.palleon-history-title {
    display: flex;
    justify-content: space-between;
}

.palleon-history-title .palleon-btn {
    font-size: 0.8rem;
    padding: 6px 10px;
}

.palleon-history-count {
    margin: 10px 0 0 0;
    font-style: italic;
    display: block;
    text-align: right;
}

#palleon-template-preview {
    position: absolute;
    z-index: 99999;
    height: auto;
    min-width: 240px;
    min-height: 100px;
    overflow: hidden;
    border: 1px solid #303030;
    background-color: #121212;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#palleon-template-preview img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

#palleon-template-preview .palleon-img-wrap {
    padding: 5px;
}

#palleon-template-preview-title {
    color: #fff;
    padding: 5px 10px 10px 10px;
    line-height: 1.3;
    font-weight: bold;
}

/* ================= MEDIA LIBRARY ================== */

#palleon-library-all-menu,
#palleon-library-my-menu,
#palleon-my-templates-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}

#palleon-library-all-menu>div,
#palleon-library-my-menu>div,
#palleon-my-templates-menu>div {
    display: flex;
}

#palleon-my-templates-menu {
    margin-top: -50px;
    justify-content: flex-end;
    z-index: 2;
}

#palleon-all-templates-noimg,
#palleon-library-all-noimg,
#palleon-library-my-noimg {
    margin: 0;
}

#palleon-img-media-library,
#palleon-overlay-img-media-library,
#palleon-overlay-delete {
    margin-top: 10px;
}

.media-library-grid .palleon-img-wrap {
    min-height: 60px;
}

/* ================= SVG LIBRARY ================== */

#palleon-svg-library-all-menu,
#palleon-svg-library-my-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}

#palleon-svg-library-all-menu>div,
#palleon-svg-library-my-menu>div {
    display: flex;
}

#palleon-svg-library-all-noimg,
#palleon-svg-library-my-noimg {
    margin: 0;
}

#palleon-svg-media-library {
    margin-top: 10px;
}

#palleon-svg-library-all .palleon-img-wrap,
#palleon-svg-library-my .palleon-img-wrap {
    padding: 5px
}

/* ================= ICONFINDER ================== */

.iconfinder-search-settings {
    display: flex;
}

.iconfinder-search-settings>div {
    flex: 1
}

.iconfinder-search-settings-left {
    padding-right: 5px;
}

.iconfinder-search-settings-right {
    padding-left: 5px;
}

.iconfinder-search-settings {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

#iconfinder-wrap {
    margin-top: 20px;
}

#palleon-iconfinder-search {
    width: 100%;
}

#palleon-iconfinder-search .material-icons {
    font-size: 18px;
}

#palleon-iconfinder .palleon-search-box button {
    margin-left: 10px;
}

#palleon-iconfinder .palleon-img-wrap {
    min-height: 50px;
}

#palleon-iconfinder-grid .palleon-element {
    background-size: 15px 15px;
    border: none !important;
    transition: all 0.2s ease-in-out;
}

#palleon-iconfinder-grid .palleon-element:hover {
    padding: 2px;
}

#iconfinder-loadmore {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 12px
}

#iconfinder-credit {
    margin-top: 20px;
    display: inline-block;
    font-style: italic;
    text-decoration: none;
    line-height: 1;
}

#iconfinder-credit:hover {
    text-decoration: underline;
}

/* ================= PEXELS ================== */

#pexels-menu {
    display: flex;
    margin-bottom: 30px;
}

#pexels-menu>div {
    flex: 1;
    width: 50%;
}

#pexels-search-options {
    display: flex;
}

#pexels-search-options select {
    margin-right: 10px
}

#palleon-pexels-search {
    margin-left: 10px;
}

a.pexels-url {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 9;
    cursor: pointer;
    line-height: 22px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
}

a.pexels-url .material-icons {
    font-size: 22px;
    line-height: 22px;
}

.pexels-grid .palleon-img-wrap {
    min-height: 60px;
}

.pexels-grid {
    margin-top: 10px
}

#pexels-loadmore {
    margin-top: 30px;
    width: 100%
}

#pexels-menu,
#pexels-output {
    transition: opacity 0.2s ease-in-out;
}

#pexels-credit {
    margin-top: 20px;
    display: inline-block;
    font-style: italic;
    text-decoration: none;
    line-height: 1;
}

#pexels-credit:hover {
    text-decoration: underline;
}

/* ================= PIXABAY ================== */

#pixabay-menu {
    display: flex;
    margin-bottom: 30px;
}

#pixabay-menu .palleon-search-box {
    flex: 1;
}

#pixabay-search-options {
    display: flex;
    flex: 2;
}

#pixabay-search-options select {
    margin-right: 10px
}

#palleon-pixabay-search {
    margin-left: 10px;
}

a.pixabay-url {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 9;
    cursor: pointer;
    line-height: 22px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
}

a.pixabay-url .material-icons {
    font-size: 22px;
    line-height: 22px;
}

.pixabay-grid .palleon-img-wrap {
    min-height: 60px;
}

.pixabay-grid {
    margin-top: 10px
}

#pixabay-loadmore {
    margin-top: 30px;
    width: 100%
}

#pixabay-menu,
#pixabay-output {
    transition: opacity 0.2s ease-in-out;
}

#pixabay-credit {
    margin-top: 20px;
    display: inline-block;
    font-style: italic;
    text-decoration: none;
    line-height: 1;
}

#pixabay-credit:hover {
    text-decoration: underline;
}

.palleon-grid.pixabay-grid.media-library-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(140px, 100%), 1fr));
    min-height: 80px;
}

.palleon-grid.pixabay-grid.media-library-grid .palleon-masonry-item-inner {
    display: flex;
    justify-content: center;
    height: 100px;
    overflow: hidden;
}

/* ================= MASONRY ================== */

.palleon-masonry {
    column-gap: 15px;
    line-height: 0;
    margin: 0;
    padding: 0;
}

.palleon-masonry.six-column {
    column-count: 6;
}

.palleon-masonry.five-column {
    column-count: 5;
}

.palleon-masonry.four-column {
    column-count: 4;
}

.palleon-masonry.three-column {
    column-count: 3;
}

.palleon-masonry.two-column {
    column-count: 2;
}

.palleon-masonry.one-column {
    column-count: 1;
}

.palleon-masonry-item {
    position: relative;
    break-inside: avoid;
    margin: 0;
    cursor: pointer;
}

.palleon-masonry-item img {
    max-width: 100%;
    width: 100%;
}

.palleon-masonry-item .favorite {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    padding: 0 0 4px 4px;
}

.palleon-masonry-item:hover .favorite {
    display: block;
}

.palleon-masonry-item .favorite .material-icons {
    font-size: 18px
}

.palleon-masonry-item-inner {
    position: relative;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.grid-item canvas {
    width:100%;
    height:auto;
    display: inline-block;
    vertical-align: bottom;
}

.grid-item:hover .palleon-masonry-item-inner,
.palleon-masonry-item-inner:hover {
    border-color: #fff;
}

.palleon-masonry-item-desc {
    width: 100%;
    display: none;
    line-height: 1.1;
    padding: 8px 4px 4px 4px;
    font-weight: 700;
    text-align: center;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
}

.palleon-masonry-item-inner:hover .palleon-masonry-item-desc {
    display: block;
}

.palleon-svg-library-delete,
.palleon-library-delete {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 9;
    cursor: pointer;
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    text-align: center;
}

.palleon-svg-library-delete .material-icons,
.palleon-library-delete .material-icons {
    font-size: 18px;
    line-height: 20px;
}

#palleon-svg-library-my .notice,
#palleon-library-my .notice{
  grid-column: auto / span 6;
  margin:0
}

#palleon-library-my .palleon-masonry-item-inner,
#palleon-svg-library-my .palleon-masonry-item-inner {
    display: flex;
    justify-content: center;
    height: 100px;
    overflow: hidden;
}

/* ================= FRONT-END EDITOR ================== */

#palleon.frontend #modal-select-img .palleon-btn-set .palleon-file-field {
    width: 100%;
}

#palleon.frontend .frame-favorite,
#palleon.frontend .element-favorite,
#palleon.frontend .template-favorite {
    display: none !important;
}

/* ================= MEDIA QUERIES ================== */

@media only screen and (max-width: 1200px) {
    #palleon-body {
        padding: 60px 200px 0 315px;
    }
    
    #palleon-svg-media-library,
    #palleon-overlay-img-media-library,
    #palleon-img-media-library {
        padding: 12px 0;
    }

    #palleon-svg-media-library .material-icons,
    #palleon-overlay-img-media-library .material-icons,
    #palleon-img-media-library .material-icons,
    #palleon-media-library .material-icons,
    .palleon-file-field .material-icons {
        display: none;
    }

    #palleon-icon-panel {
        width: 315px;
        max-width: 100%;
    }

    #palleon-icon-panel-inner {
        padding: 15px;
    }

    #palleon-crop-btns {
        flex-direction: row;
    }

    .palleon-control-wrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .palleon-control-wrap .palleon-control,
    .palleon-control-wrap .palleon-control-label {
        width: 100%;
        min-width: 100%;
    }

    .palleon-control-wrap .palleon-control-label {
        margin-bottom: 5px;
    }

    .palleon-checkbox-control,
    .palleon-toggle-control {
        justify-content: flex-start;
    }

    #palleon-toggle-left {
        left: 314px;
    }

    #palleon-adjust ul.palleon-accordion > li > a > .data-count,
    #palleon-elements ul.palleon-accordion > li > a > .data-count,
    #palleon-frames ul.palleon-accordion > li > a > .data-count{
        display: none;
    }
    #palleon-frames-wrap > li > a > .arrow,
    #palleon-elements-wrap > li > a > .arrow,
    #palleon-filters-li > a > .arrow,
    #palleon-quick-filters-li > a > .arrow {
        margin-left: auto !important;
    }
}

@media only screen and (max-width: 800px) {
    #palleon-body {
        padding: 0 0 0 75px !important;
    }

    .palleon-content-bar,
    .frappe-palleon.layers-closed .palleon-content-bar {
        right: 20px;
    }

    .palleon-templates-menu {
        width: 100%;
    }

    .palleon-templates-menu-wrap {
        margin-top: 0;
        max-width: 100%;
    }

    .palleon-select-btn-block {
        flex-direction: column;
    }

    .palleon-select-btn-block>div,
    .palleon-select-btn-block>div:first-child,
    .palleon-select-btn-block>div:last-child {
        width: 100% !important;
        padding: 0;
        margin: 0;
        border: none
    }

    .palleon-select-btn-block>div:last-child {
        margin-top: 10px;
    }

    #palleon-pages {
        margin-top:60px
    }
}

@media only screen and (max-height: 800px) {
    .palleon-icon-menu-title {
        display: none;
    }

    button.palleon-icon-menu-btn {
        padding: 15px 0
    }
}

@media only screen and (max-width: 575px) {
    html {
        font-size: 13px;
    }

    #palleon-body {
        padding: 0 0 0 54px !important;
    }

    .palleon-logo img.logo-desktop {
        display: none;
    }

    .palleon-logo img.logo-mobile {
        display: block;
    }

    #palleon-icon-menu {
        width: 54px
    }

    .palleon-icon-menu-title {
        display: none;
    }

    button.palleon-icon-menu-btn {
        padding: 15px 0
    }

    #palleon-icon-panel {
        padding: 60px 0 0 54px;
    }

    .palleon-grid.two-column {
        grid-template-columns: repeat(auto-fill, minmax(min(60px, 100%), 1fr));
    }

    .palleon-btn.palleon-lg-btn {
        padding: 14px 0px;
    }

    .palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu {
        flex-wrap: nowrap;
    }

    .palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li {
        padding: 20px 10px;
        font-size: 1rem
    }

    .palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li>.material-icons {
        display: none;
    }

    .palleon-modal-inner>.palleon-tabs>.palleon-tab,
    .palleon-modal-bg {
        padding: 20px
    }

    #palleon-library-all-menu,
    #palleon-library-my-menu,
    #palleon-my-templates-menu {
        flex-direction: column;
        align-items: flex-start;
    }

    #palleon-library-all-menu>div.palleon-search-box,
    #palleon-library-my-menu>div.palleon-search-box,
    #palleon-my-templates-menu>div.palleon-search-box {
        width: 100%;
        margin-top: 15px;
    }

    #modal-blank-canvas .palleon-control-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .palleon-control-group>div {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px
    }

    .palleon-control-group>div:last-child {
        margin-bottom: 0
    }

    #modal-blank-canvas .sp-original-input-container {
        width: 100%;
    }

    #palleon-canvas-create {
        padding: 12px 0;
        height: auto
    }

    #pexels-menu {
        flex-direction: column;
    }

    #pexels-menu>div {
        width: 100%;
    }

    #pexels-search-options select:first-child {
        margin-right: 5px;
        margin-left: 0;
    }

    #pexels-search-options select:last-child {
        margin-left: 5px;
        margin-right: 0;
    }

    #pexels-menu>div.palleon-search-box {
        margin-top: 10px
    }

    #pixabay-menu {
        flex-direction: column;
    }

    #pixabay-menu>div {
        width: 100%;
    }

    #pixabay-search-options select:first-child {
        margin-right: 10px;
        margin-left: 0;
    }

    #pixabay-search-options select:last-child {
        margin-left: 0;
        margin-right: 0;
    }

    #pixabay-menu>div.palleon-search-box {
        margin-top: 10px
    }

    .palleon-modal-close {
        top: 10px;
        right: 10px;
    }

    #modal-select-img .palleon-btn-set {
        flex-wrap: wrap;
    }

    #modal-select-img .palleon-btn-set label,
    #modal-select-img .palleon-btn-set button,
    #modal-select-img .palleon-btn-set .palleon-file-field {
        width: 100%;
        margin: 0;
    }

    #modal-select-img .palleon-btn-set label {
        margin-bottom: 10px
    }

    .palleon-template-list li {
        align-items: flex-start;
        flex-direction: column;
    }

    .palleon-template-list li div {
        width: 100%;
    }

    .palleon-template-list li div:last-child {
        margin-top: 10px;
    }

    #palleon-top-bar .palleon-btn .palleon-btn-text {
        display: none;
    }

    #palleon-top-bar .palleon-btn .material-icons {
        margin: 0;
        font-size: 18px;
    }

    .tooltip:after,
    .tooltip:before {
        display: none;
    }

    .palleon-top-bar-menu {
        padding: 0 5px;
    }

    .palleon-content-bar,
    .frappe-palleon.layers-closed .palleon-content-bar {
        right: 0px;
        bottom: 0px;
        border-radius: 0;
        padding-left: 15px;
        border-right: none !important;
        border-bottom: none !important;
    }

    .sp-palette-container {
        display: none !important;
    }

    #palleon-drag-drop-upload {
        display: none;
    }
}

// }

}