#frappe-palleon-style{
    
    // #palleon{


.toast-title {
    font-weight: 700;
    font-size: 1.3rem;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.2;
    margin-bottom: 10px
}

.toast-message {
    font-family: 'Roboto';
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-size: 1rem
}

.toast-message a,
.toast-message label {
    color: #FFF
}

.toast-message a:hover {
    color: #FFF;
    text-decoration: underline
}

.toast-close-button {
    position: relative;
    right: -.5em;
    top: -.7em;
    float: right;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    -webkit-text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    line-height: 1
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40)
}

.rtl .toast-close-button {
    left: -.3em;
    float: left;
    right: .3em
}

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-left {
    top: 12px;
    left: 12px
}

.toast-top-right {
    top: 12px;
    right: 12px
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px
}

#toast-container {
    position: fixed;
    z-index: 999999999999;
    pointer-events: none
}

#toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#toast-container>div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0;
    padding: 20px;
    width: 300px;
    border-radius: 3px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    color: #FFF;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100)
}

#toast-container>div.rtl {
    direction: rtl;
    padding: 10px
}

#toast-container>div:hover {
    cursor: pointer
}

#toast-container.toast-top-center>div,
#toast-container.toast-bottom-center>div {
    width: 300px;
    margin-left: auto;
    margin-right: auto
}

#toast-container.toast-top-full-width>div,
#toast-container.toast-bottom-full-width>div {
    width: 96%;
    margin-left: auto;
    margin-right: auto
}

.toast {
    background-color: #030303
}

.toast-success {
    background-color: #009688
}

.toast-error {
    background-color: #F44336
}

.toast-info {
    background-color: #9C27B0
}

.toast-warning {
    background-color: #FF9800
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000;
    opacity: .4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40)
}

.toast {
    margin-top:10px !important;
}

@media all and (max-width: 240px) {
    #toast-container>div {
        padding: 8px;
        width: 11em
    }

    #toast-container>div.rtl {
        padding: 8px
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }

    #toast-container .rtl .toast-close-button {
        left: -.2em;
        right: .2em
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    #toast-container>div {
        padding: 8px;
        width: 18em
    }

    #toast-container>div.rtl {
        padding: 8px
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }

    #toast-container .rtl .toast-close-button {
        left: -.2em;
        right: .2em
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    #toast-container>div {
        padding: 15px;
        width: 25em
    }

    #toast-container>div.rtl {
        padding: 15px
    }
}

// }
}