#frappe-palleon-style{

    // #palleon{


/* ================= LOADER ================== */

.palleon-loader-inner {
    background: #121212;
}

.palleon-loader {
    border: 5px solid rgba(255, 255, 255, 0.1);
    border-top-color: #6658ea;
}

/* ================= GENERAL STYLES ================== */

* {
    scrollbar-color: #717171 #303030;
}

*::-webkit-scrollbar-track {
    background: #303030;
}

*::-webkit-scrollbar-thumb {
    background-color: #999;
    border: 4px solid #303030;
}

body {
    background: #212121;
    color: #aaa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}

.text-danger {
    color: #F44336 !important;
}

.text-success {
    color: #009688 !important;
}

.text-warning {
    color: #FFC107 !important;
}

.text-white {
    color: #ffffff !important;
}

#palleon-icon-panel hr {
    border-bottom: 1px dashed #303030;
}

.notice {
    color: #aaa;
    background: rgba(255, 255, 255, 0.1);
    border-left: 4px solid #6658ea;
}

.notice.notice-danger {
    border-left: 4px solid #F44336;
}

.notice.notice-warning {
    border-left: 4px solid #FFC107;
}

.notice.notice-success {
    border-left: 4px solid #009688;
}

.palleon-img-loader {
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-top-color: #6658ea;
}

#palleon-overlay-preview {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
    background-size: 15px 15px;
}

#palleon-dummy-text {
    color: #fff;
}

#palleon-dummy-text:hover {
    color: #6658ea;
}

.palleon-pdf-img-delete {
    color: #fff;
    background: #F44336;
}

#palleon-drag-drop-upload {
    border: 3px dashed #303030;
}

#palleon-drag-drop-upload.hovered {
    background:rgba(255,255,255,0.025)
}

/* ================= PAGE STRUCTURE ================== */

#palleon-toggle-left,
#palleon-toggle-right {
    background-color: #121212;
    border: 1px solid #303030;
}

#palleon-toggle-right.closed,
#palleon-toggle-left.closed {
    background-color: #090909;
}

#palleon-toggle-left .material-icons,
#palleon-toggle-right .material-icons {
    color: #fff;
}

#palleon-toggle-left:hover .material-icons,
#palleon-toggle-right:hover .material-icons {
    color: #6658ea;
}

/* ================= TOP BAR ================== */

#palleon-top-bar {
    background: #090909;
    border-bottom: 1px solid #303030;
}

.palleon-top-bar-menu .palleon-btn-simple {
    color:#fff;
}
  
.palleon-top-bar-menu .palleon-btn-simple:disabled {
    color:#aaa
}

/* ================= DROPDOWN ================== */

ul.palleon-dropdown {
    background: #090909;
    border: 1px solid #303030;
}

ul.palleon-dropdown li {
    border-bottom: 1px solid #303030;
}

ul.palleon-dropdown li a {
    color: #aaa;
}

ul.palleon-dropdown li a:hover {
    color: #fff;
}

ul.palleon-dropdown li a .material-icons {
    color: #6658ea;
}

/* ================= CONTENT BAR ================== */

.palleon-content-bar {
    background: #121212;
    border: 1px solid #303030;
}

.palleon-content-bar .palleon-img-size {
    border-right: 1px solid #303030;
}

.palleon-content-bar .palleon-counter .palleon-btn,
.palleon-content-bar .palleon-counter .palleon-btn:hover,
.palleon-content-bar .palleon-counter .palleon-btn:focus {
    background: #121212;
    color: #aaa;
}

.palleon-content-bar .palleon-counter .palleon-btn:hover {
    color: #fff;
}

.palleon-counter .palleon-btn:disabled {
    color: rgba(255, 255, 255, 0.3);
}

.palleon-content-bar .palleon-counter:after {
    background: #303030;
}

#palleon-img-drag {
    border-right: 1px solid #303030;
    background: #121212;
    color: #aaa;
}

#palleon-img-drag:hover,
#palleon-img-drag.active {
    background: #303030;
    color: #fff;
}

/* ================= PAGES ================== */

#palleon-pages {
    background: #121212;
}

#palleon-pages>div.active>.palleon-open-page {
    background: #212121;
    color: #fff;
}

#palleon-pages>div>.palleon-open-page:hover {
    color:#fff;
}

#palleon-pages>div>.material-icons {
    color:#F44336 !important;
}

/* ================= ICON MENU ================== */

#palleon-icon-menu {
    background: #090909;
}

#palleon-icon-menu.closed {
    border-right: 1px solid #303030;
}

button.palleon-icon-menu-btn {
    color: #aaa;
    background: #090909;
}

button.palleon-icon-menu-btn.active,
button.palleon-icon-menu-btn:hover {
    color: #fff;
    background: #121212;
}

#palleon-icon-panel {
    border-right: 1px solid #303030;
    background: #121212;
}

/* ================= CONTENT ================== */

#palleon-content {
    background-color: #212121;
}

#palleon-canvas-wrap {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
    background-size: 15px 15px;
}

#palleon-canvas-loader {
    background: #121212;
}

/* ================= LAYERS ================== */

#palleon-right-col {
    border-left: 1px solid #303030;
    background: #121212;
}

.palleon-layers-title {
    background: #090909;
}

#palleon-layers li {
    color: #aaa;
    border-bottom: 1px solid #303030;
    background: #121212;
}

#palleon-layers li:first-child {
    border-top: 1px solid #303030;
}

#palleon-layers li:hover {
    color: #fff;
}

#palleon-layers li.active {
    color: #fff;
    background: #212121;
}

#palleon-layers li .layer-icons {
    background: #090909;
}

#palleon-layers li .layer-name {
    color:#aaa;
}

#palleon-layers li .layer-name:focus {
    color:#fff;
}

#palleon-layers li .layer-icons .material-icons {
    color: #aaa;
}

#palleon-layers>li>.material-icons {
    color: #6658ea;
}

#palleon-layers>li>.material-icons.layer-settings {
    background: #090909;
    color: #aaa;
}

#palleon-layers>li>.material-icons.layer-settings:hover,
#palleon-layers>li>.material-icons.layer-settings.active {
    color: #fff;
}

#palleon-layers li .layer-icons .layer-visible,
#palleon-layers li .layer-icons .layer-unlocked {
    color: #009688;
}

#palleon-layers li .layer-icons .layer-hidden,
#palleon-layers li .layer-icons .layer-locked {
    color: #FFC107;
}

#palleon-no-layer a {
    color: #6658ea;
}

#palleon-layer-delete-wrap {
    border-top: 1px dashed #303030;
}

/* ================= GRID ================== */

.palleon-frames-grid .grid-item,
.palleon-grid .grid-item {
    background: #303030;
}

.palleon-frame {
    border: 1px solid #303030;
    background: #303030;
}

.palleon-element {
    border: 1px solid #303030;
    background: #303030;
}

.type-customSVG>a:before {
    color: #4CAF50;
}

.palleon-element.light {
    background: #eee;
}

.palleon-element.dark {
    background: #303030;
}

.palleon-frame:hover,
.palleon-element:hover {
    border: 1px solid #fff;
}

.template-favorite,
.palleon-frame .frame-favorite,
.palleon-element .element-favorite {
    background: #fff;
}

.palleon-frames-grid .palleon-frame {
    border: 3px solid #303030;
}

.palleon-frames-grid .palleon-frame:hover {
    border: 3px solid #fff;
}

.grid-icon-item {
    color: #aaa;
    border: 1px solid #121212;
}

.grid-icon-item:hover {
    border: 1px solid #6658ea;
    color: #fff;
}

.grid-icon-item .material-icons {
    color: #6658ea;
}

.grid-icon-label {
    border-top: 1px solid #121212;
}

/* ================= ELEMENTS ================== */

#palleon-filters.palleon-grid img:hover,
#palleon-filter-library.palleon-grid img:hover,
#palleon-filter-library.palleon-grid canvas:hover {
    border: 3px solid #fff;
}

#palleon-filters.palleon-grid div label span {
    background: #303030;
    color: #fff;
}

#palleon-filters.palleon-grid input[type=checkbox]:checked+label img,
#palleon-filters.palleon-grid input[type=radio]:checked+label img {
    border: 3px solid #fff
}

#palleon-filters.palleon-grid input[type=checkbox]:checked+label>span,
#palleon-filters.palleon-grid input[type=radio]:checked+label>span {
    color: #090909;
    background: #fff;
}

#palleon-filter-library .grid-item.none .material-icons {
    color:#fff;
}

.palleon-element>.material-icons {
    color: #fff;
}

#palleon-shapes-grid .palleon-shape {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
    background-size: 10px 10px;
}

#palleon-shapes-grid .palleon-shape:hover {
    background: #6658ea;
}

#palleon-shapes-grid .palleon-shape svg * {
    fill: #fff !important;
}

#palleon-shapes-grid .palleon-shape:hover svg * {
    fill: #fff !important;
}

/* ================= APPS ================== */

.palleon-apps-menu-item {
    background: #303030;
}

.palleon-apps-menu-item:hover {
    background: #fff;
}

.palleon-apps-menu-item-desc {
    background: #303030;
    color: #fff;
}

.palleon-apps-menu-item:hover .palleon-apps-menu-item-desc {
    background: #fff;
    color: #111;
}

.palleon-app-peview {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
      background-size: 10px 10px;
}

.palleon-colorbrewer-item {
    border: 1px solid #303030;
}

.palleon-colorbrewer-item.active,
.palleon-colorbrewer-item:hover {
    border: 1px solid #fff;
}

/* ================= BUTTONS ================== */

.palleon-btn-simple {
    color: #aaa;
}

.palleon-btn-simple:hover,
.palleon-btn-simple.active {
    color: #fff;
}

.palleon-btn-simple:disabled {
    color: #aaa;
}

.palleon-btn-simple.star {
    color: #FF9800 !important;
}

.palleon-btn {
    background: #212121;
    color: #fff;
}

.palleon-btn:hover,
.palleon-btn.primary:hover {
    background: #5546e8;
}

.palleon-btn.active,
.palleon-btn.active:hover,
.palleon-btn.primary {
    background: #6658ea;
    color: #fff;
}

.palleon-btn.danger {
    background: #F44336;
}

.palleon-btn.danger:hover {
    background: #D32F2F;
}

.palleon-horizontal-center.active,
.palleon-vertical-center.active {
    background: #212121 !important;
}

/* ================= BLOCKS ================== */

.icon-group {
    border: 1px solid #303030;
    background: #303030
}

.icon-group .palleon-btn {
    border-right: 1px solid #303030;
}

.icon-group .palleon-btn:hover,
.icon-group .palleon-btn.active {
    background: #090909;
}

/* ================= TOOLTIP ================== */

.tooltip:after {
    background-color: #fff;
    color: #090909;
}

.tooltip:before {
    border-color: #fff transparent;
}

/* ================= PAGINATION ================== */

.palleon-pagination ul li {
    border: 1px solid #303030;
    color: #aaa;
}

.palleon-pagination ul li.active,
.palleon-pagination ul li.active:hover {
    background: #6658ea;
    border-color: #6658ea;
    color: #fff;
}

.palleon-pagination ul li:hover {
    border-color: #6658ea;
}

.palleon-pagination ul li a {
    color: #fff;
}

.palleon-accordion .palleon-pagination ul li {
    color: #aaa;
    background: #212121;
}

.palleon-accordion .palleon-pagination ul li.active,
.palleon-accordion .palleon-pagination ul li.active:hover {
    color: #fff;
    background: #6658ea;
}

.palleon-accordion .palleon-pagination ul li a,
.palleon-accordion .palleon-pagination ul li>span {
    color: #aaa;
}

.palleon-accordion .palleon-pagination ul li.active>span,
.palleon-accordion .palleon-pagination ul li a:hover {
    color: #fff
}

/* ================= ACCORDION ================== */

ul.palleon-accordion>li {
    border: 1px solid #303030;
}

ul.palleon-accordion>li.opened {
    border-color: #6658ea;
}

ul.palleon-accordion>li>a {
    color: #aaa;
}

ul.palleon-accordion>li>a:hover,
ul.palleon-accordion>li.opened>a {
    color: #fff;
}

ul.palleon-accordion>li.opened>a {
    border-bottom: 1px solid #303030
}

ul.palleon-accordion>li>a>.data-count {
    background: #6658ea;
    color: #fff;
}

.material-icons.my-favorites-star {
    color: #FFC107;
}

/* ================= RANGESLIDER ================== */

.palleon-slider {
    background: #303030;
}

.palleon-slider::-webkit-slider-thumb {
    background: #6658ea;
    border: 3px solid #121212;
}

.palleon-slider::-moz-range-thumb {
    background: #6658ea;
    border: 3px solid #121212;
}

/* ================= COUNTER ================== */

.palleon-counter .palleon-btn,
.palleon-counter .palleon-btn:hover,
.palleon-counter .palleon-btn:focus {
    background: #303030;
}

.palleon-counter .palleon-btn:disabled {
    color: rgba(255, 255, 255, 0.3);
}

/* ================= CONTROLS ================== */

.palleon-control-label span {
    background: #303030;
    color: #fff;
}

.palleon-form-field {
    background: #212121;
    border: 1px solid #303030;
    color: #aaa;
}

.palleon-form-field:focus {
    background: #212121;
    border: 1px solid #6658ea;
    color: #fff;
}

.palleon-search-wrap .material-icons {
    color: #aaa;
}

#palleon-element-search-icon.cancel,
#palleon-icon-search-icon.cancel {
    color: #F44336;
}

.palleon-sub-settings {
    border-top: 1px dashed #303030;
}

#palleon-barcode-wrap {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
    background-size: 10px 10px;
}

/* ================= COLORPICKER ================== */

.palleon-colorpicker,
.palleon-colorpicker:focus,
.palleon-form-field {
    background: #212121;
    border: 1px solid #303030;
    color: #aaa;
}

.palleon-colorpicker:focus {
    color: #fff;
}

.sp-container {
    background: #090909;
    border: 1px solid #303030;
}

.sp-palette-container {
    border-right: solid 1px #303030;
}

.sp-container button {
    color: #aaa;
}

.sp-container button:hover {
    color: #fff;
}

.sp-container button.sp-choose {
    background: #6658ea;
    color: #fff;
}

.sp-container button.sp-choose:hover,
.sp-container button.sp-choose:focus {
    background: #5546e8;
    color: #fff;
}

.sp-clear-display:before {
    color: #fff;
}

/* ================= SELECT ================== */

.palleon-select {
    color: #aaa;
    background: #212121 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23aaaaaa' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.1rem center;
    background-size: 20px 10px;
    border: 1px solid #303030;
}

.palleon-select:focus {
    border: 1px solid #6658ea;
}

.palleon-select:focus::-ms-value {
    color: #465362;
    background-color: #fff;
}

.select2-container--dark .select2-selection--single {
    background: #212121;
    border: 1px solid #303030;
}

.select2-container--dark .select2-selection--single .select2-selection__rendered {
    color: #aaa;
}

.select2-container--dark .select2-selection--single .select2-selection__arrow b {
    border-color: #aaa transparent transparent transparent;
}

.select2-container--dark .select2-selection--single .select2-selection__placeholder {
    color: #fff;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
    background: #090909;
    border-color: #303030;
}

.select2-container--dark .select2-results__option--highlighted[aria-selected] {
    background: #6658ea !important;
    color: #ffffff;
}

.select2-container--dark .select2-results__option[aria-selected=true] {
    background: #6658ea;
    color: #fff;
}

.select2-drop {
    border: 2px solid #303030;
}

.select2-drop.select2-drop-above {
    border-top: 2px solid #303030;
}

.select2-container--dark .select2-search input {
    border: 1px solid #303030;
    background: #090909;
    color: #fff
}

.select2-container--dark .select2-search input:focus {
    border: 1px solid #6658ea;
}

.select2-container--dark .select2-results__group {
    border-top: 1px solid #303030;
}

/* ================= TABS ================== */

.palleon-tabs-menu li:hover {
    color: #fff;
}

.palleon-tabs-menu li.active {
    background: #6658ea;
    color: #fff;
}

/* ================= TOGGLE ================== */

.palleon-toggle-switch {
    background: #303030;
}

.palleon-toggle-switch:before {
    background: #fff;
}

.palleon-toggle-checkbox:checked+.palleon-toggle-switch {
    background: #6658ea;
}

/* ================= CHECKBOX ================== */

.palleon-checkmark {
    background-color: #212121;
    border: 1px solid #303030;
}

.palleon-checkbox input:checked~.palleon-checkmark {
    background-color: #6658ea;
    border-color: #6658ea;
}

.palleon-checkbox .palleon-checkmark:after {
    border: solid white;
    border-width: 0 3px 3px 0;
}

/* ================= CUSTOM CURSOR ================== */

.tm-pointer-simple.tm-cursor {
    background-color: #fff;
}

/* ================= MODAL ================== */

.palleon-modal {
    background: rgba(51, 51, 51, 0.9);
}

.palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li {
    background: #212121;
}

.palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li.active {
    background: #121212;
}

.palleon-modal-inner>.palleon-tabs>.palleon-tab,
.palleon-modal-bg {
    background: #121212;
}

.palleon-modal-close {
    color: #fff;
    background: #F44336;
}

.palleon-modal-close:hover {
    color: #fff;
    background: #D32F2F;
}

#palleon-modal-onstart {
    color: #fff;
    background: #6658ea;
}

#palleon-modal-onstart:hover {
    color: #fff;
    background: #5546e8;
}

#palleon-download-as-json,
#palleon-save-as-json {
    border-top: 1px dashed #303030;
}

.palleon-select-btn-block>div:first-child {
    border-right: 1px dashed #303030
}

/* ================= TEMPLATE LIBRARY ================== */

.palleon-template-list {
    border: 1px solid #303030;
}

.palleon-template-list li {
    border-bottom: 1px solid #303030;
}

.palleon-template-list li.active {
    background:#212121
}

#palleon-history-list li .info .material-icons {
    color: #6658ea;
}

#palleon-history-list li .info span.time {
    background: #303030;
    color:#fff;
}

/* ================= MASONRY ================== */

.palleon-masonry-item .favorite {
    background: #fff;
}

.palleon-masonry-item-inner {
    border: 4px solid #303030;
}

.grid-item:hover .palleon-masonry-item-inner,
.palleon-masonry-item-inner:hover {
    border-color: #fff;
}

.palleon-masonry-item-desc {
    background: #fff;
    color: #090909
}

.palleon-svg-library-delete,
.palleon-library-delete {
    color: #fff;
    background: #F44336;
}

.palleon-svg-library-delete:hover,
.palleon-library-delete:hover {
    background: #D32F2F;
}

.palleon-grid.svg-library-grid .palleon-masonry-item-inner {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23000000'/%3E%3C/svg%3E") left top/contain #333;
      background-size: 15px 15px;
}

a.iconfinder-url,
a.pixabay-url,
a.pexels-url {
    color: #6658ea;
    background: #fff;
}

a.iconfinder-url:hover,
a.pixabay-url:hover,
a.pexels-url:hover {
    color: #544abf;
}

#iconfinder-credit,
#pexels-credit,
#pixabay-credit {
    color: #aaa;
}

#palleon-iconfinder-grid .palleon-element {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='%23FFFFFF'/%3E%3C/svg%3E") left top/contain #ccc;
    background-size: 10px 10px;
}

/* ================= RULER ================== */

.guide.v {
    border-right: 1px solid #6658ea;
}

.guide.h {
    border-bottom: 1px solid #6658ea;
}

.guide .info {
    background-color: #090909;
    border: 1px solid #212121;
    color: #fff;
}

.ruler {
    background-color:#212121;
}

.ruler .label {
    color: #aaa;
}

.ruler.h {
    border-bottom: 1px solid #444;
}

.ruler.h span.major {
    border-left: 1px solid #444
}

.ruler.h span.milestone {
    border-left: 1px solid #444
}

.ruler.v {
    border-right: 1px solid #444
}

.ruler.v span.major {
    border-top: 1px solid #444;
}

.ruler.v span.milestone {
    border-top: 1px solid #444;
}

#palleon-ruler-icon {
    background: #212121;
    border-right:1px solid #444;
    border-bottom:1px solid #444;
}

.menu-btn {
    background-color: #6658ea;
    color: #fff;
}

.rg-menu {
  border-right: 1px solid #303030;
}

.rg-menu li {
    border-bottom: 1px solid #303030;
}

.rg-menu a {
    background-color: #090909;
    color: #aaa;
}

.rg-menu a:hover,
.rg-menu a.selected {
    color: #fff;
}

/* ================= MEDIA QUERIES ================== */

@media only screen and (max-width: 800px) {
    .palleon-modal-inner>.palleon-tabs>.palleon-tabs-menu>li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

    // }
}